.event-header {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items:flex-start;
    text-align: left;
    margin-top: 10px;
}

.event-data {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: flex-start;
    text-align: left;
}

p {
    text-align: left;
}

.center-text {
    text-align: center;
    margin-top: 10px;
}