.events-page-container {
    width: 100vw;
    /* min-height: calc(100vh - (6rem + 4rem)); */
    min-height: 100vh;
    padding: 0;
    margin-left: 0 !important;
    padding: 10px

}

.events-icon-group {
    margin-left: 25px;
}
.events-pencil {
    margin-right: 7px;
}

.events-top {
    width:100vw;
    height: 175px;
}

.events-content {
    width: 100vw;
    min-height: 100%;
}
.loader {
    display: block;
    padding-left: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

