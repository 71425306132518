.page-container {
  width: 100vw;
  min-height: 100vh;
  padding: 25px 0 0 0;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

#manageSponsorsBody {
  /* background-color: silver; */
  width: 100%;
  height: 100vh;
  /* display: flex; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.table-heading {
  color: #48b080;
  text-align: center;
}

.icon-group-sponsors {
  display: flex;
}

.table-group {
  /* margin-top: 20px; */
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pencil {
  margin-right: 7px;
}

.top {
  width: 100vw;
  height: 175px;
}

.content {
  max-width: 80vw;
  min-height: 100%;
}

.image {
  width: 100px;
  height: 50.96px;
  border-radius: 3%;
}

/* tr {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.imagesize {
  max-width: 180px;
}

.inactive-image {
  opacity: 0.5;
}
