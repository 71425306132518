#container {
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
  min-width: 200px;
  max-width: 250px;
  margin: 0;
  background-color: #282e36;
}
#row {
  height: 100%;
}
#title {
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 8px;
  padding-left: 25px;
  color: #ffffff;
}
#icon-title {
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
  height: 17px;
}
.link {
  padding: 4px;
  padding-left: 20px;
  color: #ffffff;
  width: 100%;
  text-decoration: none;
  font-size: 12px;
  min-width: 200px;
  max-width: 100%;
}
.logout {
  margin-left: 5px;
}
.logout:hover {
  cursor: pointer;
  color: #43bc93;
}
.active-link {
  background-color: #43bc93;
}
.svg-container {
  margin-right: 5px;
}
.svg-icon {
  height: 100%;
  width: 15%;
  color: #ffffff;
}
#link-group,
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
}
.header-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  margin-top: 0;
}
.image {
  align-self: center;
  justify-self: center;
  margin-top: 0;
  height: 150px !important;
  width: 200px !important;
}
#link-group {
  width: 100%;
  height: 100%;
}
#nav-item {
  display: flex;
}
#footer {
  color: #ffffff;
  font-size: 10px;
  margin-top: 70px;
  min-height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.trade-mark {
  padding-top: 100px;
  padding-right: 10px;
}
