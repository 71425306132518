.page-container {
    width: 100vw;
    /* min-height: calc(100vh - (6rem + 4rem)); */
        /* margin-left: 0 !important; */
    min-height: 100vh;
    padding: 0;
    padding: 10px
}

.icon-group {
    margin-left: 25px;
}
.pencil {
    margin-right: 7px;
}

.top {
    width:100vw;
    height: 175px;
}

.content {
    width: 100vw;
    min-height: 100%;
}

.horse-image {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.horse-image-preview {
    width: 200px;
    height: 200px;
    display:block;
    margin:auto;
}
.horse-name {
    display:block;
    margin:auto;
}
.images-container {
    overflow-x: scroll;
}
.text-style {
    align-items: center;
    justify-content: flex-start;
}
.owner-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display:block;
    margin:auto;
    margin-top: 30px;
}
.owner-info {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.wrapper{
    width: 320px; 
    overflow-x:scroll;     
    white-space: nowrap;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gallery-view {
    padding: 5px;
    align-items: center;
}
.video-view {
    padding: 5px;
    margin-bottom: -5px;
    align-items: center;
}

.user-detail {
   text-align: center;
}
.vertical-line {
  border-left: 1px dashed grey;
  height: auto;
}
.horizontal-line {
    border-bottom: 1px dashed grey;
    width: auto;
  }
.content-details {
    justify-content: space-between;
    flex-direction: row;
    margin: 10;
}
h6, h4, h2 {
    color: #43BC93;
}

.horse-data {
    padding: 10px;
}

.text-center {
    text-align: center;
    margin-top: 10px;
}

.hover-row {
  cursor: pointer;
}
.loader {
    display: block;
    padding-left: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.transaction-content {
    width: 100%;
    min-height: 100%;
    display: flex;
    /* margin-bottom: 100px; */
    padding: 0 30px;
    padding-top: 0;
}
