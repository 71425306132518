.page-container {
    width: 100vw;
    /* min-height: calc(100vh - (6rem + 4rem)); */
    min-height: 100vh;
    padding: 0;
    margin-left: 0 !important;
    padding: 10px

}

.icon-group {
    margin-left: 25px;
}
.pencil {
    margin-right: 7px;
}

.top {
    width:100vw;
    height: 175px;
}

.content {
    width: 100vw;
    min-height: 100%;
}

.add-button {
    display:flex;
    justify-content:center;
    align-items:center;
}
