.plusIcon {
  font-size: 2em;
  color: #48b080;
  margin-left: 10px;
}
.plusIconFalse {
  font-size: 2em;
  color: silver;
  margin-left: 10px;
}
.trashIcon {
  font-size: 1.25em;
  color: red;
  /* margin-left: 10px; */
}
.selectedDiscipline {
  width: 250px;
  height: 38px;
  border: 1px solid silver;
  border-radius: 5px;
  padding: 6px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.rateItem {
  height: 32px;
  border: 1px solid silver;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 6px 0; */
}
.disc {
  width: 250px;
  border-radius: 5px 0 0 5px;
}
.rate {
  width: 125px;
  /* border-radius: 0 5px 5px 0; */
}
.trash {
  width: 50px;
  border-radius: 0 5px 5px 0;
}
