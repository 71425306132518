.imageCircle {
  width: 150px;
  height: 150px;
  border: 3px solid silver;
  border-radius: 100%;
}
.videoBox {
  width: 100%;
  height: 200px;
  border: 2px solid silver;
  border-radius: 10px;
}
.playIcon {
  font-size: 4em;
  color: silver;
}
.profilePreview {
  max-height: 150px;
}
