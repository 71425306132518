.calendar-page-container {
    width: 100vw;
    /* min-height: calc(100vh - (6rem + 4rem)); */
    min-height: 100vh;
    padding: 0;
    margin-left: 0 !important;
    padding: 10px

}

.calendar-icon-group {
    margin-left: 25px;
}
.calendar-pencil {
    margin-right: 7px;
}

.calendar-top {
    width:100vw;
    height: 175px;
}

.calendar-content {
    width: 100vw;
    min-height: 100%;
}

