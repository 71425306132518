@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

#manageSponsorsBody {
  /* background-color: silver; */
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 0 0 0;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

#manageSponsorsLists {
  /* background-color: darkolivegreen; */
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
  padding-left: 0;
}

.manageSponsorsHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: cornsilk; */
}

.manageSponsorsButton {
  background-color: #48b080;
  color: #fff;
}
.manageSponsorsButtonUnchanged {
  background-color: #c7c7c0;
  color: #fff;
}
.manageSponsorsButtonUnchanged:hover {
  cursor: pointer;
  opacity: 0.7;
  background-color: #48b080;
}

.manageSponsorsActiveText {
  color: #48b080;
}

.manageSponsorsPageTitle {
  font-size: 40px;
  font-weight: 400;
}

.manageSponsorsColTitle {
  font-size: 15px;
  font-weight: 400;
}

.manageSponsorsListColumn {
  /* background-color: honeydew; */
  height: 100%;
  border-right: solid 0.5px #c7c7c7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 70vh;
  /* height: 80vh; */
  overflow-y: auto;
}
.manageSponsorsListColumn:last-of-type {
  /* background-color: honeydew; */
  height: 100%;
  border-right: none;
}

.manageSponsorsListItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* margin: 5px 0 10px 0; */
  font-size: 13px;
}

.checkedText {
  color: #48b080;
}
.uncheckedText {
  color: #a8a8a8;
}

.manageSponsorsCheckbox {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.manageSponsorsCheckbox:hover {
  cursor: pointer;
}

.checked {
  background-color: #48b080;
}
.unchecked {
  border: solid 1px #a8a8a8;
}

.subListContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.isClickable {
  cursor: pointer;
}
