.dashboardContainer {
  /* margin-top: 100px; */
  width: 100%;
  min-height: calc(100vh - (6rem + 4rem));
}
#page-container {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
}
#page-container {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
}
#sidebar-container {
  min-height: 100vh !important;
  width: 100%;
  margin-left: -1rem;
  margin-right: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
