.users-page-container {
  width: 100% !important;
  /* min-height: calc(100vh - (6rem + 4rem)); */
  min-height: 100%;
  padding: 0;
  margin: 5px !important;
}

/* top row left column */
.users-top-row {
  min-height: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.users-left-article {
  height: 100%;
  margin-left: 35px;
}

.users-title-button-section {
  height: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
}

.users-left-button {
  background: #48b080 0% 0% no-repeat padding-box;
  font-weight: bolder;
  height: 40px;
  width: 150px;
  opacity: 1;
  text-align: left;
  margin-left: 20px;
  font-size: 10px;
  letter-spacing: -0.28px;
  color: #ffffff;
}

.user-sort-links {
  text-decoration: none;
  display: flex;
  margin-bottom: 0;
  margin-top: 25px;
  list-style: none;
  padding-left: 10px;
}

.user-sort-links > li {
  text-decoration: none;
  padding-right: 30px;
  padding-left: 0;
  font-size: 15px;
  color: #cbcbcb;
}

.user-sort-links > span {
  color: #cbcbcb;
}

.users-right-article {
  display: flex;
  justify-content: flex-end;
  margin-right: 55px;
  padding: 10px;
}

.users-button-right {
  width: 117px;
}

/* table content */
.users-content {
  width: 100%;
  min-height: 100%;
  display: flex;
  /* margin-bottom: 100px; */
  padding: 0 30px;
  padding-top: 0;
}

#users-label {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
  height: 17px;
}

.users-icon-group {
  margin-left: 25px;
  font-size: 18px;
  color: #cbcbcb;
  display: flex;
}

.users-icon-group > div {
  cursor: pointer;
}
.users-pencil {
  margin-right: 9px;
}

li:hover {
  cursor: pointer;
}

th {
  color: #48b080;
  font: 16px;
  border-top: none;
}

th:hover {
  cursor: pointer;
}

td {
  font-size: 14px;
  background-color: #ffffff;
}

h1 {
  text-align: left;
  font-size: 40px;
  letter-spacing: -0.92px;
  color: #48b080;
  opacity: 1;
}
